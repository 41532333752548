<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Marketing Groups</a></li>
    </ol>
    <h4 class="page-header">MARKETING GROUPS</h4>
    <panel variant="panel-default">
      <vue-good-table
          :columns="columns"
          :lineNumbers="true"
          :pagination-options="{ enabled: true, position: 'bottom' }"
          :rows="groups"
          :search-options="{ enabled: true }"
      >
        <div slot="table-actions">
          <button
              class="btn btn-sm btn-black"
              @click.prevent="groupModal(null)"
          >
            <i class="fa fa-plus"></i> Create New
          </button>
          <download-excel
              v-if="groups.length"
              :data="groups"
              :title="'Marketing Groups'"
              class="btn btn-primary"
              name="Groups.xls"
          >
            <i class="fa fa-file-excel"></i> Export Excel
          </download-excel>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label === 'Action'">
            <button
                class="btn btn-sm btn-info"
                type="button"
                @click="groupModal(props.row)">
              Edit
            </button>
          </span>
        </template>
      </vue-good-table>
    </panel>
    <modal
        :show-modal="showModal"
        @close="showModal = false"
    >
      <template v-slot:header>
        <h5>
          <span v-if="group">Edit </span><span v-else>Create</span> Group
        </h5>
      </template>
      <form @submit.prevent="save">
        <div v-html="$error.handle(error)" />
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Name</label>
            <input
                v-model="name"
                class="form-control"
                required
                type="text"
            >
          </div>
          <div class="col-md-12 form-group">
            <button class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Action",
          field: "id",
        }
      ],
      loading: true,
      showModal: false,
      group: null,
      name: '',
      error: '',
      groups: []
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/marketing-group').then(response => {
        let glist = response.data.data;
        this.groups = glist.map(x => {
          return {'id': x.id, 'name': x.name}
        });
      });
    },
    save() {
      if (this.group) {
        this.edit();
        return;
      }
      this.$axios.post('/api/v1/marketing-group', {'name': this.name}).then(() => {
        this.showModal = false;
        this.fetch();
        this.name = '';
        this.group = null;
        this.$toastr.s('Marketing group created successfully!');
      });
    },
    edit() {
      this.$axios.put('/api/v1/marketing-group/' + this.group.id, {'name': this.name}).then(() => {
        this.showModal = false;
        this.name = '';
        this.group = null;
        const index = this.groups.findIndex(d => d.id === this.group.id);
        if (index !== -1) this.groups[index] = this.group;
        this.$toastr.s("Group updated successfully")
      }).catch(error => {
        this.error = error.response;
      });
    },
    groupModal(group) {
      if (group != null) {
        this.group = group;
        this.name = group.name;
      } else {
        this.group = null;
      }
      this.showModal = true;
    }
  }
}
</script>